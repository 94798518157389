import React, { useEffect, useState } from 'react'

import { IArtworkCarousel } from '../types/index'
import { getCaption } from '../utils'

const ArtworkCarousel: React.FC<IArtworkCarousel> = ( { images, imageHeight  } ) => {

    // (!) undefined check to enable 'gatsby build'
    const isMobileInit = typeof window !== 'undefined' ? window.innerWidth <= 992 : false
    const [isMobile, setIsMobile] = useState(isMobileInit)
    const [isCarouselLoaded, setIsCarouselLoaded] = useState(false)

    useEffect(() => {

        // carousel reference no longer used in return()
        // let carousel: { dispose: () => void }
        // isCarouselLoaded check to avoid memory leakage
        if(!isCarouselLoaded && images.length > 1) {
            const Carousel = require('bootstrap.native/dist/components/carousel-native')
            // const carousel = new Carousel('.carousel', { interval: false })
            const carousel = new Carousel('#artwork-carousel', { interval: false })
            console.log('# ArtworkCarousel useEffect() - carousel loaded')
            setIsCarouselLoaded(true)
        }

        // MediaQueryList - alternative: if(!isMobile && window.innerWidth <= 992) {
        // https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList
        // https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/onchange
        // https://stackoverflow.com/questions/57371174/replacement-of-deprecated-mediaquerylist-addlistener-event-in-angular-8
        const mql = window.matchMedia('(max-width: 992px)')
        const checkMobile = (e: any) => {
            if (e.matches && !isMobile) {
                setIsMobile(true)

            }
            else if (!e.matches && isMobile) {
                setIsMobile(false)
            }
        }
        mql.addListener(checkMobile)
        // mql.addEventListener('change', checkMobile)  // not yet supported on Safari

        return () => {
            // (!) dispose() results in ERROR in PROD Netlify: carousel-native.js:301 Uncaught TypeError:
            //     Cannot read property 'Carousel' of null at HTMLDivElement.I (carousel-native.js:301)
            // if(images.length > 1 && !!carousel) carousel.dispose()
            mql.removeListener(checkMobile)
            // mql.removeEventListener('change', checkMobile) // not yet supported on Safari
        }
    }, [isMobile, isCarouselLoaded])


    const height = isMobile && images.length > 1 ? imageHeight - 120 : imageHeight

    console.log('# ArtworkCarousel - isMobile['+isMobile+'] imageHeight['+imageHeight+'] height['+height+']')

    const styles = {
        image: {
            maxHeight: height + 'px',
            width: 'auto',
            objectFit: 'cover' // prevents image distortion
        },
        caption: {
            marginTop: '10px',
            fontSize: '13px'
        }
    }
    // When multiple images: fixes height and thumbnails position on desktop
    // @ts-ignore
    if(images.length > 1) styles.image['height'] = height

    return (
        // @ts-ignore
        <div id="artwork-carousel" className="carousel slide" data-bs-ride="carousel" data-bs-touch="true" align="center">
            <div id="carousel-inner" className="carousel-inner">
                {images.map((image, index) =>
                    <div key={index} className={`carousel-item ${index === 0 && 'active'}`}>
                        <img style={styles.image} src={image.src} alt=""/>
                        {!image.caption ?
                            <div className="spacer-10"/> :
                            <div style={styles.caption}>
                                <a href={!image.link ? '#' : image.link} target="_blank" rel="noreferrer noopener">
                                    {getCaption(image)}
                                </a>
                            </div>
                        }
                    </div>
                )}
            </div>
            {images.length > 1 &&
                <div id="carousel-thumbnails">
                    <ol className="carousel-indicators">
                        {!isMobile ?
                            images.map((image, index) =>
                                <li key={index} className={`${index === 0 && 'active'}`}>
                                    <img src={image.src} data-bs-target="#artwork-carousel" data-bs-slide-to={index}/>
                                </li>
                            ) :
                            images.map((image, index) =>
                                <li key={index} className={`${index === 0 && 'active'}`} data-bs-target="#artwork-carousel" data-bs-slide-to={index}/>
                            )
                        }
                    </ol>
                    {/* (!) 30/12/2020: #carousel-thumbnails > carousel-controls (arrows) disabled due to positioning issue for the 4 carousels
                                        no real added value anyways: thumbnails suffice to navigate
                        <div>
                            <a href="#carousel" className="carousel-control-prev" role="button" data-slide="prev">
                                <span><i className="fa fa-chevron-left" aria-hidden="true"></i></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a href="#carousel" className="carousel-control-next" role="button" data-slide="next">
                                <span><i className="fa fa-chevron-right" aria-hidden="true"></i></span>
                                <span className="sr-only">Next</span>
                            </a>
                        </div>
                    */}
                </div>
            }
            {images.length === 1 && <div className="spacer-20"/>}
        </div>
    )
}

export default ArtworkCarousel
