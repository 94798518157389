import React from 'react'

import { IMetadataRow, IArtworkTemplate } from '../types/index'
import { t } from '../components/Translator'
import { links, metadata_labels, unknown } from '../constants'
import Layout, { Section } from '../components/Layout'
import ArtworkCarousel from '../components/ArtworkCarousel'

// For now 4 layouts (x) contains thumbnails ( > 1 image)
const layouts = [

    // type 0 : 01_beeldjes_maria_gabriel(2); 06_kromstafbekroning; 07_kruisbeeld
    { col_img: 'col-md-5', col_text: 'col-md-7', image_height: 600 },

    // type 1 : 02_doksaal(2)
    { col_img: 'col-md-7', col_text: 'col-md-5', image_height: 550 },

    // type 2 : 13_schrijn_karel_de_goede; 15_tronende_madonna
    { col_img: 'col-md-6', col_text: 'col-md-6', image_height: 700 },

    // type 3 : 03_grafmonument_carondelet; 04_kalvarie_catharina_barbara; 05_koorgestoelte(2); 08_laatste_avondmaal; 09_marteling_hippolytus;
    //          10_passieretabel; 11_passietaferelen; 12_retabel_anna; 14_schrijn_sint_elooi; 16_wandtapijtenreeks(9)
    { text_full_width: true, image_height: 600 },

]

const MetadataRow: React.FC<IMetadataRow> = ({ label, value}) => {
    return (
        <div className="metadata-row">
            <div className="metadata-label">{t(label)}</div>
            <div className="metadata-value" dangerouslySetInnerHTML={{ __html: t(value) }}/>
        </div>
    )
}

const ArtworkTemplate: React.FC<IArtworkTemplate> = ({ pageContext }) => {

    // @ts-ignore
    const { filename } = pageContext
    const content = require('../../content/artworks/' + filename)
    const carouselImages = content.carousel_images
    // (!) second_item solution, for now only 02_doksaal
    const secondItem = !!content.second_item && content.second_item
    // (!) third_item solution, for now only 12_retabel_anna
    const thirdItem = !!content.third_item && content.third_item

    // @ts-ignore
    const { type } = pageContext
    const layout = layouts[type]
    const textFullWidth = !!layout.text_full_width

    const parentLink = links[4]
    const breadcrumb = { parentLink: parentLink.to, parentLinkLabel: parentLink.label , currentLinkLabel: content.title }

    const carousel =
        <div>
            <ArtworkCarousel images={carouselImages} imageHeight={layout.image_height}/>
        </div>

    const getMetadata = (source: object) => {
        return(
            Object.entries(metadata_labels).map(([key, value], index) =>
                // @ts-ignore
                !!source[key] && <MetadataRow key={index} label={metadata_labels[key]} value={key === 'creator' && source[key] === 'unknown' ? unknown : source[key]}/>
            )
        )
    }

    const getItemMetadata = (source: object, setSpacer: boolean) => {
         return !!source && (
            <div>
                {setSpacer && <div className="spacer-30"></div>}
                {/*@ts-ignore*/}
                <h1 className="short">{t(source.title)}</h1>
                {getMetadata(source)}
            </div>
        )
    }

    const metadata =
        <div>
            {getItemMetadata(content, false)}
            {getItemMetadata(secondItem, true)}
            {getItemMetadata(thirdItem, true)}
        </div>

    const sections = <div className="post-content"><Section content={content.sections[0]}/></div>

    return (
        <Layout head={content.head} breadcrumb={breadcrumb}>
            {textFullWidth ?
                <div className="row"><div className="col-12">{carousel}</div></div> :
                <div className="row">
                    <div className={layout.col_img}>
                        {carousel}
                    </div>
                    <div className={layout.col_text}>
                        {metadata}
                        <div className="spacer-20"></div>
                        {sections}
                    </div>
                </div>
            }
            {textFullWidth &&
                <div className="row">
                    <div className="col-lg-12 col-xl-4">
                        {metadata}
                        <div className="spacer-20"></div>
                    </div>
                    <div className="col-lg-12 col-xl-8">
                        {sections}
                    </div>
                </div>
            }
        </Layout>
    )
}

export default ArtworkTemplate
